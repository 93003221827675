// composable to get the viewport of the customers device (only client side).
export default (): ViewPort => {
	if (process.server) {
		console.error("composable is not useable serverside");
		return;
	}
	if (window.matchMedia('(max-width: 47.9375rem)').matches) {
		return 'mobile';
	} else if (window.matchMedia('(max-width: 74.9375rem)').matches) {
		return 'tablet';
	} else {
		return 'desktop';
	}
};

type ViewPort = "mobile" | "tablet" | "desktop";